import React from 'react';
import './App.css';
import Menu from './Globals/Components/Menu'; // Import the Menu component

// App Component
const App = () => {
  return (
    <div className="app-container">
      {/* Header Section */}
      <header className="app-header">
        <h1>🔑 Pleasure Quest</h1>
      </header>
        <Menu /> 
      <footer className="app-footer">
      </footer>
    </div>
  );
};

export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from '../../Pages/Home';
import NFT from '../../Pages/NFT';
import Tasks from '../../Pages/Tasks';
import Friends from '../../Pages/Friends';
import Stats from '../../Pages/Stats';

const Menu = () => {
  return (
    <Router>
      <div className="app">
        {/* Define the routes for the app */}
        <Routes>
          <Route path="/tap" element={<Home />} />
          <Route path="/nft" element={<NFT />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/stats" element={<Stats />} />
        </Routes>

        {/* Bottom Menu */}
        <nav className="bottom-menu">
          <Link to="/tap">
            <img src="/img/tap.svg" alt="TAP" />
            <span>TAP</span>
          </Link>
          <Link to="/nft">
            <img src="/img/nft.svg" alt="NFT" />
            <span>NFT</span>
          </Link>
          <Link to="/tasks">
            <img src="/img/tasks.svg" alt="Tasks" />
            <span>Tasks</span>
          </Link>
          <Link to="/friends">
            <img src="/img/friends.svg" alt="Friends" />
            <span>Friends</span>
          </Link>
          <Link to="/stats">
            <img src="/img/stats.svg" alt="Stats" />
            <span>Stats</span>
          </Link>
        </nav>
      </div>
    </Router>
  );
};

export default Menu;
